import React from 'react';
import styles from './index.module.scss';

export default function Switch({ checked, onCheck, children }) {
    return (
        <label className={styles.wrapper}>
            <div className={styles.switch}>
                <input type="checkbox" checked={checked} onChange={onCheck} />
                <span className={styles.slider}></span>
            </div>
            {children}
        </label>
    );
}
