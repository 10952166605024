import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './index.module.scss';
import Switch from '../switch';

export default function Layout({
    sw = {
        setDarkMode: () => {},
        setAudio: () => {},
        values: { darkMode: 1, audio: 1 }
    },
    fullWidth = false,
    children
}) {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    const [msg, setMsg] = useState('');
    const muteSw = useRef();

    const theme = sw.values.darkMode ? styles.dark : styles.light;

    const toggleSw = (func, val, key) => {
        func(val);
        localStorage.setItem(`sw:${key}`, val);
    };

    useEffect(() => {
        if (localStorage.getItem('sw:dark') !== null) {
            sw.setDarkMode(+localStorage.getItem('sw:dark'));
        } else if (
            typeof window !== `undefined` &&
            window?.matchMedia('(prefers-color-scheme: light)').matches
        ) {
            sw.setDarkMode(0);
        }

        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                navigator.userAgent
            )
        ) {
            // Mobile stuff
        }
        if (localStorage.getItem('sw:audio') !== null) {
            sw.setAudio(+localStorage.getItem('sw:audio'));
        }
        const handleKeyPress = ({ key }) => {
            switch (key) {
                case 'm':
                    return toggleSw(sw.setAudio, +!sw.values.audio, 'audio');
                case 'l':
                    return toggleSw(
                        sw.setDarkMode,
                        +!sw.values.darkMode,
                        'dark'
                    );
                default:
                    return;
            }
        };
        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [sw]);

    return (
        <div className={`${theme} ${styles.wrapper}`}>
            {/* <span
                className={`${styles.status} ${msg ? styles.title : ''}`}
                onClick={() => alert('tappy tappy')}
            />
            <span className={styles.label}>{msg}</span> */}
            <main
                className={`${styles.main} ${
                    fullWidth ? styles.fullWidth : ''
                }`}
            >
                {children}
            </main>
            <footer>
                <div className={styles.footer}>
                    <div>
                        © {new Date().getFullYear()},{' '}
                        {data?.site?.siteMetadata?.title || 'noCAPTCHA'}. i am
                        not a robot.
                    </div>
                    <div>
                        <Switch
                            checked={!sw.values.darkMode}
                            onCheck={() =>
                                toggleSw(
                                    sw.setDarkMode,
                                    +!sw.values.darkMode,
                                    'dark'
                                )
                            }
                        >
                            <span role="img" aria-label="lightSwitch">
                                💡
                            </span>
                        </Switch>
                        <Switch
                            ref={muteSw}
                            checked={sw.values.audio}
                            onCheck={() =>
                                toggleSw(
                                    sw.setAudio,
                                    +!sw.values.audio,
                                    'audio'
                                )
                            }
                        >
                            {sw.values.audio ? (
                                <span role="img" aria-label="soundOn">
                                    🔊
                                </span>
                            ) : (
                                <span role="img" aria-label="soundOff">
                                    🔇
                                </span>
                            )}
                        </Switch>
                    </div>
                    <ul className={styles.links}>
                        <li>
                            <a href="https://www.instagram.com/nocaptcha.live/">
                                ig
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitch.tv/nocaptcha_live">
                                twitch
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCfEo4ytvbN_gywsl2oFjNXg">
                                youtube
                            </a>
                        </li>
                        {/* <li>
                            <a href="javascript:alert(`you're early, glad to have you here. big things coming soon! -- RGB`)">
                                about
                            </a>
                        </li> */}
                    </ul>
                </div>
            </footer>
        </div>
    );
}
